@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
  overflow-y: hidden;
  overscroll-behavior: contain;
  height: 100%;
}

*, ::before, ::after {
  border: none;
}

:root {
    --animation-speed: 1000ms;
    --animation-speed-fast: 250ms;
    --default-cell-bg-color: theme('colors.white');
    --default-cell-border-color: theme('colors.black');
    --default-cell-border-width: 1px;                       /* Somehow 1px here is the same as border-2 */
    --default-cell-text-color: theme('colors.black');
    --unknown-cell-bg-color: theme('colors.unknown');
    --unknown-cell-border-color: theme('colors.unknown');
    --unknown-cell-text-color: theme('colors.black');
    --absent-cell-bg-color: theme('colors.absent');
    --absent-cell-text-color: theme('colors.white');
    --correct-cell-bg-color: theme('colors.correct');
    --correct-cell-text-color: theme('colors.white');
    --present-cell-bg-color: theme('colors.present');
    --present-cell-text-color: theme('colors.white');
    --error-cell-bg-color: theme('colors.error');
    --error-cell-text-color: theme('colors.white');
    --empty-cell-border-color: theme('colors.empty');
    --empty-cell-bg-color: theme('colors.gray.100');
}

.dark {
    --default-cell-bg-color: theme('colors.black');
    --default-cell-border-color: theme('colors.slate.100');
    --default-cell-text-color: theme('colors.white');
    --unknown-cell-bg-color: theme('colors.unknown-dark');
    --unknown-cell-border-color: theme('colors.unknown-dark');
    --unknown-cell-text-color: theme('colors.white');
    --correct-cell-bg-color: theme('colors.correct-dark');
    --present-cell-bg-color: theme('colors.present-dark');
    --absent-cell-bg-color: theme('colors.absent-dark');
    --empty-cell-bg-color: theme('colors.black');
    --empty-cell-border-color: theme('colors.empty-dark');
}

.high-contrast {
    --correct-cell-bg-color: theme('colors.correct-hc');
    --present-cell-bg-color: theme('colors.present-hc');
    --error-cell-bg-color: theme('colors.error-hc');
}

.fixed-game {
    overflow-y: hidden;
    position: relative;
}

.cell-focus-animation {
    animation: onFocusCell ease 1.25s infinite;
}

.cell-reveal {
  animation-duration: 0.25s;
  animation-timing-function: linear;
  animation-fill-mode: backwards;
}

.cell-reveal-prefill {
    animation-duration: 0.25s;
    animation-timing-function: linear;
    animation-fill-mode: backwards;
    -webkit-animation-name: revealPrefillCharCell;
    animation-name: revealPrefillCharCell;
  }

.cell-reveal.absent {
  -webkit-animation-name: revealAbsentCharCell;
          animation-name: revealAbsentCharCell;
}

.cell-reveal.correct {
  -webkit-animation-name: revealCorrectCharCell;
          animation-name: revealCorrectCharCell;
}

.cell-reveal.present {
  -webkit-animation-name: revealPresentCharCell;
          animation-name: revealPresentCharCell;
}

.cell-reveal.error {
  -webkit-animation-name: revealErrorCharCell;
          animation-name: revealErrorCharCell;
}

.hint-unhighlight {
    -webkit-animation-name: unHighlightHint;
    animation-name: unHighlightHint;
    animation-duration: 10s;
    animation-timing-function: linear;
}

.hint-unhighlight-dark {
    -webkit-animation-name: unHighlightHintDark;
    animation-name: unHighlightHintDark;
    animation-duration: 10s;
    animation-timing-function: linear;
}

.no-text-select {
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

div.fixed-font {
    font-family: monospace;
}

div.line-breaks {
    white-space: pre-wrap;
}

svg.cursor-pointer {
  transition: all var(--animation-speed-fast);
}

svg.cursor-pointer:hover {
  transform: scale(1.2);
}

.jiggle {
  animation: jiggle linear;
  animation-duration: 0.5s;
}

/* NOTE: delay on .spread, .spread-container, and .spread-container-button must be the same
         and must match FIRST_GUESS_ANIMATION_TIME_MS in settings.ts */
.spread {
  -webkit-animation: spread linear;
          animation: spread linear;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
          animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

.spread-container {
  -webkit-animation: spread-container linear;
          animation: spread-container linear;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
          animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

.spread-container-button {
  -webkit-animation: spread-container-button linear;
          animation: spread-container-button linear;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
          animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

.menu {
    background-color: white;
}

.menu-dark {
    background-color: #07111d;
}

.menu-open {
    z-index: 30;
}

.menu-closed {
    z-index: 1;
}

.cue.overlay {
    mix-blend-mode: overlay;
}

.app-logo::before, .app-logo::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}

.navbar {
    margin-bottom: 4%;
}

.navbar-content {
    display: flex;
    height: 3.5rem;
    align-items: center;
    justify-content: space-between;
}

.float-container {
    padding: 0px;
    width: 80%;
    align-content:center;
}

.float-child {
    width: 50%;
    float: left;
    align-items: center;
}

.padding-0 {
    padding-right: 0;
    padding-left: 0;
}

.padding-10 {
    padding-right: 10px;
    padding-left: 10px;
}

.padding-20 {
    padding-right: 20px;
    padding-left: 20px;
}

.mx-key {
    margin-left: 0.20rem; 
    margin-right: 0.20rem;
}

@-webkit-keyframes revealAbsentCharCell {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    border-width: var(--default-cell-border-width);
    color: var(--default-cell-text-color);
  }

  50% {
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    border-width: var(--default-cell-border-width);
    color: var(--default-cell-text-color);
  }

  50.1% {
    background-color: var(--absent-cell-bg-color);
    border-color: var(--absent-cell-bg-color);
    border-width: var(--default-cell-border-width);
  }

  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
}

@keyframes revealAbsentCharCell {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    border-width: var(--default-cell-border-width);
    color: var(--default-cell-text-color);
  }

  50% {
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg);
    background-color: var(--default-cell-bg-color);
    border-width: var(--default-cell-border-width);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }

  50.1% {
    background-color: var(--absent-cell-bg-color);
    border-color: var(--absent-cell-bg-color);
    border-width: var(--default-cell-border-width);
  }

  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
}

@-webkit-keyframes revealCorrectCharCell {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    border-width: var(--default-cell-border-width);
    color: var(--default-cell-text-color);
  }

  50% {
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    border-width: var(--default-cell-border-width);
    color: var(--default-cell-text-color);
  }

  50.1% {
    background-color: var(--correct-cell-bg-color);
    border-color: var(--correct-cell-bg-color);
    border-width: var(--default-cell-border-width);
  }

  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
}

@keyframes revealCorrectCharCell {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    border-width: var(--default-cell-border-width);
    color: var(--default-cell-text-color);
  }

  50% {
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    border-width: var(--default-cell-border-width);
    color: var(--default-cell-text-color);
  }

  50.1% {
    background-color: var(--correct-cell-bg-color);
    border-color: var(--correct-cell-bg-color);
    border-width: var(--default-cell-border-width);
  }

  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
}

@-webkit-keyframes revealPresentCharCell {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    border-width: var(--default-cell-border-width);
    color: var(--default-cell-text-color);
  }

  50% {
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    border-width: var(--default-cell-border-width);
    color: var(--default-cell-text-color);
  }

  50.1% {
    background-color: var(--present-cell-bg-color);
    border-color: var(--present-cell-bg-color);
    border-width: var(--default-cell-border-width);
  }

  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
}

@keyframes revealPresentCharCell {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    border-width: var(--default-cell-border-width);
    color: var(--default-cell-text-color);
  }

  50% {
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    border-width: var(--default-cell-border-width);
    color: var(--default-cell-text-color);
  }

  50.1% {
    background-color: var(--present-cell-bg-color);
    border-color: var(--present-cell-bg-color);
    border-width: var(--default-cell-border-width);
  }

  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
}

@-webkit-keyframes revealErrorCharCell {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    border-width: var(--default-cell-border-width);
    color: var(--default-cell-text-color);
  }

  50% {
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    border-width: var(--default-cell-border-width);
    color: var(--default-cell-text-color);
  }

  50.1% {
    background-color: var(--error-cell-bg-color);
    border-color: var(--error-cell-bg-color);
    border-width: var(--default-cell-border-width);
    color: var(--error-cell-text-color);
  }

  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
}

@keyframes revealErrorCharCell {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    border-width: var(--default-cell-border-width);
    color: var(--default-cell-text-color);
  }

  50% {
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    border-width: var(--default-cell-border-width);
    color: var(--default-cell-text-color);
  }

  50.1% {
    background-color: var(--error-cell-bg-color);
    border-color: var(--error-cell-bg-color);
    border-width: var(--default-cell-border-width);
    color: var(--error-cell-text-color);
  }

  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
}

@-webkit-keyframes revealPrefillCharCell {
    0% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      background-color: var(--correct-cell-bg-color);
      border-width: 0px;
      color: var(--correct-cell-text-color);
    }
  
    50% {
      -webkit-transform: rotateX(90deg);
      transform: rotateX(90deg);
      background-color: var(--correct-cell-bg-color);
      border-width: 0px;
      color: var(--correct-cell-text-color);
    }
  
    50.1% {
      background-color: var(--correct-cell-bg-color);
      border-width: 0px;
    }
  
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
    }
  }
  
  @keyframes revealPrefillCharCell {
    0% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      background-color: var(--correct-cell-bg-color);
      border-width: 0px;
      color: var(--correct-cell-text-color);
    }
  
    50% {
      -webkit-transform: rotateX(90deg);
      transform: rotateX(90deg);
      background-color: var(--correct-cell-bg-color);
      border-color: var(--correct-cell-border-color);
      border-width: 0px;
      color: var(--default-cell-text-color);
    }
  
    50.1% {
      background-color: var(--correct-cell-bg-color);
      border-width: 0px;
    }
  
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
    }
  }

  @-webkit-keyframes unHighlightHint {
    0% {
        background-color: none;
        color: black;
    }

    20% {
        background-color: var(--bg-absent);
        border-width: 0px;
        color: black;
    }

    20.01% {
        background-color: var(--bg-absent);
        border-width: 0px;
        color: black;
    }

    100% {
      background-color: var(--bg-absent);
      border-width: 0px;
      color: black;
    }
  }
  
  @keyframes unHighlightHint {
    0% {
        background-color: none;
        color: black;
    }
    
    20% {
        background-color: var(--bg-absent);
        border-width: 0px;
        color: black
    }

    20.01% {
        background-color: var(--bg-absent);
        border-width: 0px;
        color: black;
    }

    100% {
      background-color: var(--bg-absent);
      border-width: 0px;
      color: black;
    }
  }

  @-webkit-keyframes unHighlightHintDark {
    0% {
        background-color: none;
    }

    20% {
        background-color: var(--bg-absent-dark);
        border-width: 0px;
        color: rgb(203 213 225);    /* slate-300 */
    }

    20.01% {
        background-color: var(--bg-absent-dark);
        border-width: 0px;
        color: rgb(203 213 225);    /* slate-300 */
    }

    100% {
      background-color: var(--bg-absent-dark);
      border-width: 0px;
      color: rgb(203 213 225);      /* slate-300 */
    }
  }
  
  @keyframes unHighlightHintDark {
    0% {
        background-color: none;
    }

    20% {
        background-color: var(--bg-absent-dark);
        border-width: 0px;
        color: rgb(203 213 225);    /* slate-300 */
    }

    20.01% {
        background-color: var(--bg-absent-dark);
        border-width: 0px;
        color: rgb(203 213 225);    /* slate-300 */
    }

    100% {
      background-color: var(--bg-absent-dark);
      border-width: 0px;
      color: rgb(203 213 225);      /* slate-300 */
    }
  }

@keyframes onFocusCell {
    0% {
        border: 2px solid var(--default-cell-border-color);
        border: 2px solid rgba(255, 0, 0, 1.0);
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
    }

    12.5% {
        border: 2px solid var(--default-cell-border-color);
        border: 2px solid rgba(255, 0, 0, 0.9);
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
    }

    25% {
        border: 2px solid var(--default-cell-border-color);
        border: 2px solid rgba(255, 0, 0, 0.7);
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
    }

    37.5% {
        border: 2px solid var(--default-cell-border-color);
        border: 2px solid rgba(255, 0, 0, 0.5);
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
    }

    50% {
        border: 2px solid var(--default-cell-border-color);
        border: 2px solid rgba(255, 0, 0, 0.3);
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
    }

    62.5% {
        border: 2px solid var(--default-cell-border-color);
        border: 2px solid rgba(255, 0, 0, 0.5);
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
    }

    75% {
        border: 2px solid var(--default-cell-border-color);
        border: 2px solid rgba(255, 0, 0, 0.7);
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
    }

    87.5% {
        border: 2px solid var(--default-cell-border-color);
        border: 2px solid rgba(255, 0, 0, 0.9);
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
    }

    100% {
        border: 2px solid var(--default-cell-border-color);
        border: 2px solid rgba(255, 0, 0, 1.0);
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
    }
}

.shadowed {
  text-shadow: 1px 1px 1px #000000;
}

@keyframes jiggle {
  0% {
    transform: translate(0, 0);
  }
  16.67% {
    transform: translate(-0.5rem, 0);
  }
  33.33% {
    transform: translate(0.5rem, 0);
  }
  50% {
    transform: translate(-0.5rem, 0);
  }
  66.67% {
    transform: translate(0.5rem, 0);
  }
  83.33% {
    transform: translate(-0.5rem, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes spread {
  0%, 20%, 35% {
    width: 22px;
    height: 22px;
    border: none;
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 0px;
    color: var(--default-cell-text-color);
  }

  12.5%, 27.5% {
    width: 22px;
    height: 22px;
    border-style: none;
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 0px;
    color: var(--default-cell-bg-color);
  }

  60% {
    width: 36px;
    height: 22px;
    border-style: none;
    margin-left: 2px;
    margin-right: 2px;
    padding-top: 0px;
    color: var(--default-cell-text-color);
  }

  60.1% {
    width: 36px;
    height: 36px;
    border-width: var(--default-cell-border-width);
    border-color: var(--default-cell-bg-color);
    border-style: solid;
    margin-left: 2px;
    margin-right: 2px;
    padding-top: 4px;
    color: var(--default-cell-text-color);
  }

  90%, 100% {
    width: 36px;
    height: 36px;
    border-width: var(--default-cell-border-width);
    border-color: var(--default-cell-border-color);
    background-color: var(--default-cell-bg-color);
    border-style: solid;
    margin-left: 2px;
    margin-right: 2px;
    padding-top: 4px;
    color: var(--default-cell-text-color);
  }
}

@keyframes spread-container {
  0%, 60% {
    margin-top: 4px;
  }

  60.1%, 100% {
    margin-top: -1px;
  }
}

@keyframes spread-container-button {
  0%, 40% {
    border-color: var(--default-cell-border-color);
  }

  50.1%, 100% {
    border-color: var(--default-cell-bg-color);
    background-color: var(--default-cell-bg-color);
  }
}

@keyframes bulge-left {
    20% {
        transform: scale(2.8);
        left: -20px;
    }
}

@keyframes bulge-right {
    20% {
        transform: scale(2.8);
        left: 44px;
    }
}

@keyframes bulge-right-skill-stop {
    20% {
        transform: scale(2.8);
    }
}

@keyframes logo-flip-timer {
  0%, 50.8%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    background-color: var(--unknown-cell-bg-color);
    border-color: var(--unknown-cell-border-color);
    border-width: 1;
    color: var(--unknown-cell-text-color);
  }

  0.4% {
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg);
    background-color: var(--unknown-cell-bg-color);
    border-color: var(--unknown-cell-border-color);
    color: var(--unknown-cell-text-color);
  }

  0.401% {
    background-color: var(--correct-cell-bg-color);
    border-color: var(--correct-cell-bg-color);
    color: var(--correct-cell-text-color);
  }

  0.8%, 50% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    background-color: var(--correct-cell-bg-color);
    border-color: var(--correct-cell-border-color);
    color: var(--correct-cell-text-color);
  }

  50.4% {
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg);
    background-color: var(--correct-cell-bg-color);
    border-color: var(--correct-cell-border-color);
    color: var(--correct-cell-text-color);
  }

  50.401% {
    background-color: var(--unknown-cell-bg-color);
    border-color: var(--unknown-cell-bg-color);
  }

  50.8%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    background-color: var(--unknown-cell-bg-color);
    border-color: var(--unknown-cell-border-color);
    border-width: 1;
    color: var(--unknown-cell-text-color);
  }
}

@keyframes logo-flip-timer-fast {
  0%, 50.8%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    background-color: var(--unknown-cell-bg-color);
    border-color: var(--unknown-cell-border-color);
    border-width: 1;
    color: var(--unknown-cell-text-color);
  }

  1.6% {
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg);
    background-color: var(--unknown-cell-bg-color);
    border-color: var(--unknown-cell-border-color);
    color: var(--unknown-cell-text-color);
  }

  1.601% {
    background-color: var(--correct-cell-bg-color);
    border-color: var(--correct-cell-bg-color);
    color: var(--correct-cell-text-color);
  }

  3.2%, 50% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    background-color: var(--correct-cell-bg-color);
    border-color: var(--correct-cell-border-color);
    color: var(--correct-cell-text-color);
  }

  51.6% {
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg);
    background-color: var(--correct-cell-bg-color);
    border-color: var(--correct-cell-border-color);
    color: var(--correct-cell-text-color);
  }

  51.601% {
    background-color: var(--unknown-cell-bg-color);
    border-color: var(--unknown-cell-bg-color);
  }

  53.2%, 100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    background-color: var(--unknown-cell-bg-color);
    border-color: var(--unknown-cell-border-color);
    border-width: 1;
    color: var(--unknown-cell-text-color);
  }
}

.bulge-left {
    animation-name: bulge-left;
    animation-duration: 1.8s;
    /* animation-delay must sync with HINT_DISPLAY_DELAY_TIME_MS */
    animation-delay: 0.8s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    position: absolute;
}

.bulge-right {
    animation-name: bulge-right;
    animation-duration: 1.8s;
    /* animation-delay must sync with HINT_DISPLAY_DELAY_TIME_MS */
    animation-delay: 0.8s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    position: absolute;
}

.bulge-left-skill-stop {
    animation-name: bulge-left;
    animation-duration: 1.8s;
    animation-delay: 1.5s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    position: absolute;
}

.bulge-right-skill-stop {
    animation-name: bulge-right-skill-stop;
    animation-duration: 1.8s;
    animation-delay: 1.5s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    position: absolute;
}

.live-logo {
  animation-name: logo-flip-timer;
  animation-duration: 60s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: backwards;
  position: relative;
  top: 9px
}

.live-logo-fast {
  animation-name: logo-flip-timer-fast;
  animation-duration: 15s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: backwards;
  position: relative;
  top: 9px
}

.static-logo {
    position: relative;
    top: 9px
}

ins.adsbygoogle[data-ad-status="unfilled"] {
    display: none !important;
}

a:link {
    color: blue;
    background-color: transparent;
    text-decoration: none;
}

textarea {
    resize: none;
}

.btn {
  @apply px-5 py-2 text-sm font-medium text-gray-900 focus:outline-none bg-gray-100 rounded-sm border-solid border border-gray-200 hover:bg-blue-500 hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 dark:hover:text-white dark:hover:bg-blue-700 dark:disabled:bg-gray-900 dark:disabled:text-gray-500 disabled:bg-gray-100 disabled:text-gray-300 disabled:cursor-not-allowed;
}

.btn-primary {
  @apply text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-sm text-sm px-5 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 dark:disabled:bg-gray-500 disabled:bg-gray-300 disabled:text-gray-400 disabled:cursor-not-allowed;
}

.btn-green {
  @apply text-white bg-correct hover:bg-green-800 focus:ring-2 focus:ring-green-100 font-medium rounded-sm text-sm px-5 py-2 focus:outline-none dark:disabled:bg-gray-500 disabled:bg-gray-300 disabled:text-gray-400 disabled:cursor-not-allowed;
}

.btn-orange {
  @apply text-white bg-orange-500 hover:bg-orange-800 focus:ring-2 focus:ring-orange-800 font-medium rounded-sm text-sm px-5 py-2 focus:outline-none dark:disabled:bg-gray-500 disabled:bg-gray-300 disabled:text-gray-400 disabled:cursor-not-allowed;
}

.btn-link {
  @apply text-blue-900 dark:text-slate-300 text-sm disabled:cursor-not-allowed disabled:text-gray-300 dark:disabled:text-gray-600;
}

.line-cell:before,
.line-cell::after {
  top: 1px;
  display: block;
  flex: 1 1 auto;
  height: 1px;
  content: '';
  margin-top: 9px;
  @apply dark:bg-slate-600 bg-slate-300;
}

.line-cell-large:before,
.line-cell-large::after {
  top: 1px;
  display: block;
  flex: 1 1 auto;
  height: 1px;
  content: '';
  margin-top: 14px;
  @apply dark:bg-slate-600 bg-slate-300;
}
